<template>
  <div style="background-color: var(--notclickable-color); height: 100vh; width: 100vw;">
    <b-container class="min-vh-100 d-flex flex-column">
      <BackButton back/>
      <b-row>
        <b-col cols="1" />
        <b-col>
          <h1 style="margin-bottom: 2rem; color: var(--white-color);">Hilfe</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-top: 1.5rem; margin-bottom: 1.5rem;">
          <b-row class="menuItem" style="margin-top: 0;">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;"
                @click="$router.push('/appTutorial/1')">
                App Tutorial
                <b-icon class="icons" scale="1.5" icon="chevron-right" />
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row>
            <b-col cols="1" />
            <b-col cols="10">
              <hr style="background-color: var(--white-color)">
            </b-col>
            <b-col cols="1" />
          </b-row>

          <b-row class="menuItem" style="margin-top: 0;">
            <b-col cols="1" />
            <b-col cols="10">
              <h3 style="color: var(--white-color); margin-top: 0.7rem; width: 100%;"
                @click="$router.push('/bikeTutorial/1')">
                Bike Tutorial
                <b-icon class="icons" scale="1.5" icon="chevron-right" />
              </h3>
            </b-col>
            <b-col cols="1" />
          </b-row>
          <b-row>
            <b-col cols="1" />
            <b-col cols="10">
              <hr style="background-color: var(--white-color)">
            </b-col>
            <b-col cols="1" />
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import BackButton from '../components/BackButton.vue'

  export default {
    name: 'MenuHelp',
    components: {
      BackButton,
    }
  }
</script>

<style scoped>
  .menuItem {
    color: var(--white-color);
    margin-top: 1rem;
    cursor: pointer;
  }

  .icons {
    position: absolute;
    right: 1rem;
  }

  .whitecontainer {
    background-color: var(--white-color);
    border-radius: 0.5rem;
    text-align: center;
  }

  .whitecontainerItems {
    width: 2rem;
    height: 2rem;
  }
</style>